import { AxiosInstance, AxiosResponse } from "axios";
import httpclient from "@/utilities/helpers/http.client.axios";
import ManualModel from "@/models/models/manual.model";

export class ManualManager {
    private readonly api: AxiosInstance = httpclient;

    async getManualsAsync(vesselId: number): Promise<ManualModel[] | Error> {
        try {
            const response: AxiosResponse<ManualModel[]> = await this.api.get(`/Manual/Manuals/${vesselId}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async viewManualAttachmentAsync(id: number): Promise<void | Error> {
        try {
            const response = await this.api.get(`/Manual/GetManualAttachmentContentById/${id}`, { responseType: "blob" });
            const url = window.URL.createObjectURL(response.data);
            window.open(url, "_blank");
        } catch (error) {
            return error as Error;
        }
    }

    async createAttachmentsAsync(vesselId: number, request: FormData): Promise<void | Error> {
        try {
            const response = await this.api.post(`/Manual/CreateAttachments/${vesselId}`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async deleteAttachment(id: number) {
        try {
            const response = await this.api.delete(`/Manual/DeleteManualAttachment/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }
}

export const manualManager = new ManualManager();
