import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "messages-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dialog, {
      visible: _ctx.showDialog,
      "onUpdate:visible": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDialog) = $event)),
        _ctx.closed
      ],
      draggable: false,
      modal: true,
      class: _normalizeClass(['p-fluid fw-bold confirm-dialog', _ctx.$route.name]),
      header: _ctx.header
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('global.button.cancel'),
          icon: "pi pi-times",
          class: "secondary-button",
          onClick: _ctx.closed
        }, null, 8, ["label", "onClick"]),
        (_ctx.type == 'confirm')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              label: _ctx.$t('global.button.confirm'),
              icon: "pi pi-check",
              class: "primary-button",
              onClick: _ctx.onConfirm
            }, null, 8, ["label", "onClick"]))
          : (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              disabled: !_ctx.canDelete,
              label: _ctx.$t('global.button.delete'),
              icon: "pi pi-check",
              class: "danger-button",
              onClick: _ctx.onConfirm
            }, null, 8, ["disabled", "label", "onClick"]))
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
            return (_openBlock(), _createElementBlock("span", { key: message }, _toDisplayString(message), 1))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["visible", "class", "header", "onUpdate:visible"])
  ]))
}