<template>
    <div id="data-table">
        <div v-if="uniqueSettingsKey || showBulkAction || showGroupAction || showGlobalSearch || showServerSearch" class="btn-search-header">
            <div class="left-buttons">
                <ActionMenu v-if="showBulkAction" :class="['text-nowrap', 'splitbutton-custom', isMobileView ? 'mr-1' : 'mr-2']" :button-text="$t('table.bulkAction')"
                    :action-menu-items="table.bulkActionMenuItems" />
                <ActionMenu v-if="showGroupAction" :class="['text-nowrap', 'splitbutton-custom', isMobileView ? 'mx-1' : 'mx-2']" :button-text="$t('table.groupBy')"
                    :action-menu-items="groupByMenuItems" />
                <ActionMenu ref="filterRef" v-if="showDropdownFilter" :class="['text-nowrap', 'splitbutton-custom', isMobileView ? 'mx-1' : 'mx-2']" :button-text="dropdownFilterButtonText"
                    :action-menu-items="table.dropdownFilterMenuItems" :isMultiSelect="table.isFilterMultiSelect" />

                <CancelButton v-if="showGroupAction" :class="{ 'me-4': !isMobileView }" :on-click="onClickCancelGrouping" />
            </div>

            <div v-if="showGlobalSearch || showServerSearch" class="search-container">
                <span v-if="showGlobalSearch" style="display: flex; max-width: 100%;">
                    <InputText ref="searchControl" v-model="filters['global'].value" class="search-box" :placeholder="$t('table.search')" />
                    <div class="search-svg-container">
                        <img class="search-svg" alt="" :src="require('../../../../public/icons/search.svg')" />
                    </div>
                </span>
                <span v-if="showServerSearch" style="display: flex; max-width: 100%;">
                    <Chips ref="chips" v-model="searchQuery" @remove="search()" @keyup="searchInputKeyUp" @keydown="searchInputKeyDown">
                        <template #chip="slotProps">
                            <div @click="editChip(slotProps)">
                                <span>{{ slotProps.value }}</span>
                            </div>
                        </template>
                    </Chips>
                    <div class="search-svg-container">
                        <img class="search-svg" alt="" :src="require('../../../../public/icons/search.svg')" />
                    </div>
                    <Button v-if="!isMobileView" class="secondary-button mx-1" @click="advancedSearch()"> ... </Button>
                    <Button v-if="!isMobileView" class="secondary-button" :disabled="!searchQuery.length" @click="clear()" @keydown.enter="clear()">
                        {{ $t("global.button.clear") }}
                    </Button>
                    <OverlayPanel ref="fieldsPanel">
                        <Listbox ref="fieldsPanelList" :model-value="selectedHint" :options="hintList" :select-on-focus="true" option-label="text" class="hint-list"
                            @update:modelValue="hintItemChanged" />
                    </OverlayPanel>
                </span>
                <Button v-if="isMobileView" class="secondary-button col-12 mt-2" :label="$t('advancedSearch.title')" @click="advancedSearch()"></Button>
            </div>

            <div class="right-header-container">
                <slot name="header" class="mb-3"></slot>
                <i v-if="uniqueSettingsKey" v-tooltip.bottom="$t('table.editColumns.tooltip')" class="pi pi-cog ms-3 settings-icon my-2" @click="hideColumnsSettings()" />
            </div>
        </div>
        <DataTable ref="table" :key="updateKey" v-model:filters="filters" v-model:expandableRowGroups="expandableRowGroups" v-model:expandedRowGroups="expandedRowGroups"
            v-model:selection="selectedItemsInternal" responsive-layout="stack" :global-filter-fields="globalFilterFields" :value="value?.filter(i => !i.hidden)" :scrollable="!isMobileView"
            data-key="id" :data-test-id="table.dataTestId" :loading="isLoading" row-group-mode="subheader" :paginator="paginator" @page="onPaginate" @update:rows="onRowsChanged"
            :first="currentPage * currentPageSize" :rows="currentPageSize" :row-class="table.getRowClass" removable-sort :paginator-template="isMobileView
                ? 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport'
                : 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
                " :rows-per-page-options="[5, 10, 20, 50]" :current-page-report-template="$t('table.paginationMessage', {
                    first: '{first}',
                    last: '{last}',
                    totalRecords: '{totalRecords}'
                })
                    " :sort-field="groupRowsBy" :group-rows-by="groupRowsBy" @update:selection="selectItems">
            <template #loading> {{ loadingText }}</template>
            <template #empty>
                <div v-if="!isLoading && dataError?.length">
                    {{ errorText }}
                </div>
                <div v-else>
                    <slot name="empty"></slot>
                </div>
            </template>
            <Column v-if="showSelect" key="id" selection-mode="multiple" :frozen="true" class="short-column" />
            <template v-if="groupRowsBy" #groupheader="{ data }">
                <div v-if="groupRowsBy === 'createdByUser.id'" class="d-flex flex-row flex-nowrap">
                    <img :src="data.createdByUser.imageSource ?? require('../../../../public/no.avatar.svg')" class="mx-1" />
                    <span class="text-nowrap">
                        {{ data.createdByUserFullName }}
                    </span>
                </div>
                <span v-else>
                    <Checkbox v-model="selected[data[groupRowsBy]]" :binary="true" class="select-grouped-item" @change="select(data[groupRowsBy])" />
                    {{ data[groupRowsBy] }}
                </span>
            </template>
            <Column v-for="columnDefinition of table.columns.filter(c => !c.hideColumn)" :key="columnDefinition.fieldName" :sortable="!!columnDefinition.sortField"
                :sort-field="columnDefinition.sortField" :export-header="columnDefinition.header" :field="columnDefinition.fieldName" :frozen="columnDefinition.frozen"
                :align-frozen="columnDefinition.alignFrozen" :class="{ 'action-col': columnDefinition.fieldType === fieldType.IconAction }" :style="getWidth(columnDefinition)"
                :header="columnDefinition.hideHeader ? '' : columnDefinition.header">
                <template #filter="{ filterModel }">
                    <InputText v-model="filterModel.value" type="text" class="p-column-filter" :placeholder="`Search by ${columnDefinition.header}`" />
                </template>
                <template #body="{ field, data }">
                    <div v-if="!columnDefinition.condition || columnDefinition.condition(data)" :data-test-id="columnDefinition.dataTestId">
                        <div v-if="columnDefinition.fieldType === fieldType.Image">
                            <img alt="" :src="columnDefinition.getData(data)" style="width: 2rem;" :class="['mx-1', { 'cursor-pointer': columnDefinition.additionalData?.callback }]" @click="() => {
                                if (columnDefinition.additionalData?.callback) {
                                    columnDefinition.additionalData.callback(data);
                                }
                            }
                                " />
                        </div>
                        <div v-else-if="columnDefinition.fieldType === fieldType.ImageWithText" class="imageWithText-container" :style="{ 'max-width': columnDefinition.width }">
                            <img v-if="!columnDefinition.additionalData.invert && columnDefinition.additionalData.getImageSrc(data)" alt=""
                                :src="columnDefinition.additionalData.getImageSrc(data)"
                                :style="columnDefinition.additionalData.getStyle ? columnDefinition.additionalData.getStyle() : {}" />

                            <div class="long-text-container">
                                {{ columnDefinition.additionalData.getTextRelatedToImage(data) }}
                            </div>

                            <img v-if="columnDefinition.additionalData.getImageSrc(data) && columnDefinition.additionalData.invert" alt=""
                                :src="columnDefinition.additionalData.getImageSrc(data)"
                                :style="columnDefinition.additionalData.getStyle ? columnDefinition.additionalData.getStyle() : { marginRight: '1rem' }" />
                        </div>
                        <div v-else-if="columnDefinition.fieldType === fieldType.ButtonAction">
                            <Button class="primary-button justify-content-center text-nowrap w-100"
                                :style="overrideButtonBackgroundToPrimary ? { backgroundColor: '#00a7e1', fontSize: '0.75rem' } : { fontSize: '0.75rem' }"
                                @click="() => columnDefinition.additionalData.callback(data)">
                                {{ columnDefinition.additionalData.name ?? columnDefinition.additionalData.getName(data) }}
                            </Button>
                        </div>
                        <div v-else-if="columnDefinition.fieldType === fieldType.ButtonWithCustomIcon">
                            <Button class="primary-button button-custom-icon" :style="overrideButtonBackgroundToPrimary ? { backgroundColor: '#00a7e1' } : {}"
                                :disabled="columnDefinition.additionalData.isDisabled(data)" @click="() => columnDefinition.additionalData.callback(data)">
                                <img v-if="columnDefinition.additionalData.getIcon(data)" :src="columnDefinition.additionalData.getIcon(data) ?? ''" />
                                {{ columnDefinition.additionalData.name ?? columnDefinition.additionalData.getName(data) }}
                            </Button>
                        </div>
                        <div v-else-if="columnDefinition.fieldType === fieldType.DynamicButtonAction" style="display: flex">
                            <Button class="primary justify-content-center text-nowrap" :style="columnDefinition.additionalData.getStyle(data)"
                                :icon="columnDefinition.additionalData.getIcon(data) ?? ''" :label="columnDefinition.additionalData.name ?? columnDefinition.additionalData.getName(data)"
                                @click="() => columnDefinition.additionalData.callback(data)">
                            </Button>

                            <Button v-if="columnDefinition.additionalData.containsSecondary(data)" class="primary" :style="columnDefinition.additionalData.secondaryStyle(data)"
                                :icon="columnDefinition.additionalData.iconOnly() ?? ''" @click="() => columnDefinition.additionalData.secondaryButtonAction(data)" />
                        </div>
                        <div v-else-if="columnDefinition.fieldType === fieldType.EventStatusText">
                            <!-- Bad approach: this specific data shouldn't be here -->
                            <ColoredDropdownComponent v-model="data.eventStatus" :class="{ 'w-100': isMobileView }" :options="table['coloredDropdownOptions']"
                                :colored-dropdown-colors="table['coloredDropdownColors']" @update:modelValue="changeEvent => columnDefinition.additionalData.callback(changeEvent, data)">
                            </ColoredDropdownComponent>
                            <input v-model="data[field]" type="hidden" />
                        </div>

                        <div v-else-if="columnDefinition.fieldType === fieldType.ColoredDropdown">
                            <!-- Bad approach: this specific data shouldn't be here -->
                            <ColoredDropdownComponent v-model="data[field]" :class="{ 'w-100': isMobileView }" :options="table['coloredDropdownOptions']"
                                :colored-dropdown-colors="table['coloredDropdownColors']" @update:modelValue="value => columnDefinition.additionalData.callback(value, data)">
                            </ColoredDropdownComponent>
                            <input v-model="data[field]" type="hidden" />
                        </div>

                        <div v-else-if="columnDefinition.fieldType === fieldType.LinkText">
                            <label :class="['link-text', { 'text-end': isMobileView }]" @click="() => columnDefinition.additionalData.callback(data)">
                                {{ getData(data, field) }}
                            </label>
                        </div>
                        <div v-else-if="columnDefinition.fieldType === fieldType.IconAction">
                            <label :class="['cursor-pointer mb-2', { 'text-end': isMobileView }]">
                                <img v-tooltip.bottom="columnDefinition.additionalData.header" :src="columnDefinition.additionalData.icon"
                                    @click="() => columnDefinition.additionalData.callback(data)" />
                            </label>
                        </div>
                        <div v-else-if="columnDefinition.fieldType === fieldType.CheckboxAction" :class="['d-flex', { 'text-end': isMobileView }]">
                            <Checkbox :id="'checkbox' + data[table.keyField]" :key="updateCheckboxKeys.id" v-model="data[field]" :binary="true" @change="() => {
                                updateCheckboxKeys.id = (updateCheckboxKeys.id ?? 0) + 1; // redraw state
                                columnDefinition.additionalData.callback(data);
                            }
                                " />
                            <label :for="'checkbox' + data[table.keyField]" class="ms-2 checkbox-label">{{ columnDefinition.additionalData.name }}</label>
                        </div>
                        <div v-else-if="columnDefinition.fieldType === fieldType.Menu
                            && (columnDefinition.additionalData.hide ? !columnDefinition.additionalData?.hide(data) : true)" class="text-center">
                            <Button v-if="data.hasChild" class="primary-button" :label="columnDefinition.label" :icon="columnDefinition.icon" icon-pos="right"
                                @click="columnDefinition.additionalData.callback(data)" />
                            <i class="pi pi-ellipsis-v p-2 cursor-pointer" @click="ev => toggle(ev, data[field], $refs)" />
                            <Menu :id="table.tableId" :ref="`menu${data[field]}`" :model="columnDefinition.additionalData.getItems(data)" class="py-3" :popup="true">
                                <template #item="{ item }">
                                    <div class="px-4 py-2">
                                        <button class="w-100 p-link flex align-items-center pl-4 text-color border-noround" :disabled="item.disabled" @click="item.command">
                                            <img alt="" :src="item.icon" class="align-text-bottom" />
                                            <span class="ms-3"> {{ item.label }} </span>
                                            <div v-if="item.subLabel" class="sub-label">
                                                {{ item.subLabel }}
                                            </div>
                                        </button>
                                    </div>
                                </template>
                            </Menu>
                        </div>
                        <div v-else-if="columnDefinition.fieldType === fieldType.LongText" class="long-text-container" :style="columnDefinition.style">
                            {{ getData(data, field) }}
                        </div>
                        <div v-else-if="columnDefinition.fieldType === fieldType.Pre" class="text-break pure-text tex-end" style="white-space: pre;">
                            {{ getData(data, field) }}
                        </div>
                        <div v-else-if="columnDefinition.fieldType === fieldType.Date" class="text-break pure-text tex-end">
                            {{ getData(data, field)?.toString().serializedToLocalDateString() }}
                        </div>
                        <!--   Find out why this is hardcoded    -->
                        <div v-else :class="['text-break pure-text tex-end', { 'text-end': isMobileView }]" style="text-transform: capitalize;">
                            <img alt="" v-if="columnDefinition.fieldType === fieldType.AvatarField && data.createdByUser !== null"
                                :src="data.createdByUser.imageSource ?? require('../../../../public/no.avatar.svg')" class="mx-1" />
                            {{ getData(data, field) }}
                        </div>
                    </div>
                </template>
            </Column>
        </DataTable>

        <component :is="component" v-bind="componentData" />
    </div>
</template>
<script src="./data.table.ts"></script>
<style lang="scss" scoped src="./data.table.scoped.scss"></style>
<style lang="scss" src="./data.table.scss"></style>
