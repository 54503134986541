import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store, { IStore } from "./index";
import { UserModel } from "@/models/models/user.model";
import { RequestLoginModel } from "@/models/request.models/request.login.model";
import { accessTokenKey, userKey } from "@/utilities/constants/auth.data";
import { isTokenExpired } from "@/utilities/helpers/jwt.decode";
import { serviceModule } from "./service.module";
import { fleetModule } from "./fleet.module";
import { vesselManager } from "@/managers/vessel.manager";

export interface IAuthState {
    currentUser?: UserModel | undefined;
    loggingIn: boolean;
    loginErrors: Error[];
    isAuthorized: boolean;
}

@Module({
    store,
    name: "auth",
    dynamic: true,
    namespaced: true
})
class AuthModule extends VuexModule implements IAuthState {
    private readonly store!: IStore;

    private user: UserModel | undefined = this.getCurrentUserFromLocalStorage();

    public loggingIn = false;

    public loginErrors: Error[] = new Array<Error>();

    public get isAuthorized(): boolean {
        const accessToken = sessionStorage.getItem(accessTokenKey) ?? localStorage.getItem(accessTokenKey);

        return !(!accessToken || !this.currentUser?.role || isTokenExpired(accessToken as string));
    }

    public get currentUser(): UserModel | undefined {
        return this.user;
    }

    public get authErrors() {
        return this.loginErrors;
    }

    @Mutation
    public setLoggingIn(value: boolean) {
        this.loggingIn = value;
    }

    @Mutation
    public setLogout() {
        localStorage.removeItem(userKey);
        localStorage.removeItem(accessTokenKey);
        sessionStorage.removeItem(accessTokenKey);
        this.user = undefined;
        this.loginErrors = new Array<Error>();
        serviceModule.resetState();
        fleetModule.resetState();
    }

    @Mutation
    public setLoginSuccessful({
        user,
        accessToken,
        rememberInformation
    }: {
        accessToken: string;
        user: UserModel;
        rememberInformation: boolean;
    }) {
        this.loggingIn = false;

        if (rememberInformation) {
            localStorage.setItem(accessTokenKey, accessToken);
        } else {
            sessionStorage.setItem(accessTokenKey, accessToken);
        }

        localStorage.setItem(userKey, JSON.stringify(user));

        this.user = user;

        this.loginErrors = new Array<Error>();
    }

    @Mutation
    public setLoginUnsuccessful(error: Error) {
        this.loggingIn = false;

        this.loginErrors.push(error);
    }

    @Action({ rawError: true })
    public async login(loginModel: RequestLoginModel) {
        localStorage.removeItem(userKey);
        localStorage.removeItem(accessTokenKey);
        sessionStorage.removeItem(accessTokenKey);

        this.context.commit("setLoggingIn", true);

        const response = await this.store.userManager.login(loginModel);

        if (response instanceof Error) {
            return this.context.commit("setLoginUnsuccessful", response);
        }

        return this.context.commit("setLoginSuccessful", {
            accessToken: response.accessToken,
            user: response.user,
            rememberInformation: loginModel.rememberInformation
        });
    }

    @Action({ rawError: true })
    public async logout() {
        await this.store.userManager.logout();
        return this.context.commit("setLogout");
    }

    private getCurrentUserFromLocalStorage() {
        const userObject = localStorage.getItem(userKey);

        if (userObject) {
            return JSON.parse(userObject) as UserModel;
        }

        return undefined;
    }

    @Action({ rawError: true })
    public async canAccessVesselAsync(vesselId: number): Promise<boolean> {
        const res = await vesselManager.canAccessAsync(vesselId);
        return !(res instanceof Error) && res;
    }

    @Action({ rawError: true })
    public async canAccessAgentAsync(agentId: number): Promise<boolean> {
        return agentId === this.currentUser?.serviceProviderId;
    }
}

export const authModule: AuthModule = getModule(AuthModule);
