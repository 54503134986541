import { AxiosInstance, AxiosResponse } from "axios";
import httpClient from "../utilities/helpers/http.client.axios";
import { RequestLoginModel } from "@/models/request.models/request.login.model";
import { ResponseLoginModel } from "@/models/response.models/response.login.model";
import { UserModel } from "@/models/models/user.model";
import { RequestUserModel } from "@/models/request.models/request.user.model";
import { RequestUserRoleChangeModel } from "@/models/request.models/request.user.role.change.model";
import SkillModel from "@/models/models/skills/skill.model";
import UserSkillModel from "@/models/models/skills/user.skill.model";
import SkillLevelModel from "@/models/models/skills/skill.level.model";

export class UserManager {
    private readonly api: AxiosInstance = httpClient;

    async login(requestLoginModel: RequestLoginModel): Promise<ResponseLoginModel | Error> {
        try {
            const response: AxiosResponse<ResponseLoginModel> = await this.api.post("/User/Login", requestLoginModel);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async logout(): Promise<void | Error> {
        try {
            const response: AxiosResponse<void> = await this.api.post("/User/logout");
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getUsers(): Promise<UserModel[] | Error> {
        try {
            const response: AxiosResponse<UserModel[]> = await this.api.get(`/User/GetUsers`);

            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getActiveUsersAsync(): Promise<UserModel[] | Error> {
        try {
            const response: AxiosResponse<UserModel[]> = await this.api.get(`/User/ActiveUsers`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getActiveUsersWithSkillAsync(): Promise<UserModel[] | Error> {
        try {
            const response: AxiosResponse<UserModel[]> = await this.api.get(`/User/ActiveUsersWithSkill`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async createAsync(requestUserModel: RequestUserModel): Promise<UserModel | Error> {
        try {
            const response: AxiosResponse<UserModel> = await this.api.post("/User/Create", requestUserModel);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async updateAsync(requestUserModel: RequestUserModel): Promise<UserModel | Error> {
        try {
            const response: AxiosResponse<UserModel> = await this.api.put("/User/Update", requestUserModel);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async inviteUserAsync(requestUserModel: RequestUserModel): Promise<string | Error> {
        try {
            const response: AxiosResponse<string> = await this.api.post("/User/Invite", requestUserModel);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async isInviteViable(id: string): Promise<number | Error> {
        try {
            const response: AxiosResponse<number> = await this.api.get(`/User/IsInviteViable/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getRegistrationAsync(id: string): Promise<RequestUserModel | Error> {
        try {
            const response: AxiosResponse<RequestUserModel> = await this.api.get(`/User/Registration/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async sendPasswordResetAsync(request: { email: string }): Promise<string | Error> {
        try {
            const response: AxiosResponse<string> = await this.api.post(`/User/PasswordReset`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async hasPasswordResetExpiredAsync(id: string): Promise<boolean | Error> {
        try {
            const response: AxiosResponse<boolean> = await this.api.get(`/User/HasPasswordResetExpired/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getPasswordResetUserEmailAsync(id: string): Promise<string | Error> {
        try {
            const response: AxiosResponse<string> = await this.api.get(`/User/PasswordResetUserEmail/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async updatePasswordAsync(request: { email: string; password: string; passwordResetId: string }): Promise<boolean | Error> {
        try {
            const response: AxiosResponse<boolean> = await this.api.put(`/User/UpdatePassword`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async updateUserRole(requestRoleModel: RequestUserRoleChangeModel): Promise<UserModel | Error> {
        try {
            const response: AxiosResponse<UserModel> = await this.api.put("/User/UpdateUserRole", requestRoleModel);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async deleteUser(id: number): Promise<UserModel | Error> {
        try {
            const response: AxiosResponse<UserModel> = await this.api.delete(`/User/DeleteUser/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async disableUserAsync(id: number): Promise<void | Error> {
        try {
            const response: AxiosResponse<void> = await this.api.get(`/User/Disable/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getSkillsAsync(): Promise<SkillModel[] | Error> {
        try {
            const response: AxiosResponse<SkillModel[]> = await this.api.get(`/User/Skills`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getSkillLevelsAsync(): Promise<SkillLevelModel[] | Error> {
        try {
            const response: AxiosResponse<SkillLevelModel[]> = await this.api.get(`/User/SkillLevels`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async addSkillAsync(request: SkillModel): Promise<SkillModel | Error> {
        try {
            const response: AxiosResponse<SkillModel> = await this.api.post(`/User/AddSkill`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async updateSkillAsync(request: SkillModel): Promise<SkillModel | Error> {
        try {
            const response: AxiosResponse<SkillModel> = await this.api.put(`/User/UpdateSkill`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async removeSkillAsync(request: SkillModel): Promise<SkillModel | Error> {
        try {
            const response: AxiosResponse<SkillModel> = await this.api.post(`/User/RemoveSkill`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getUserSkillsAsync(userId: number): Promise<UserSkillModel[] | Error> {
        try {
            const response: AxiosResponse<UserSkillModel[]> = await this.api.get(`/User/UserSkills/${userId}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async updateUserSkillAsync(userId: number, requests: UserSkillModel[]): Promise<UserSkillModel[] | Error> {
        try {
            const response: AxiosResponse<UserSkillModel[]> = await this.api.put(`/User/UpdateUserSkills/${userId}`, requests);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }
}

export const userManager = new UserManager();
