export const fleetSearchQueryKey = "searchQueryKey";

export const showHistoryKey = "showHistoryKey";

export const showMyEventLogsKey = "showMyEventLogs";

export const serviceSearchQueryKey = "serviceSearchQueryKey";

export const serviceReportFormSearchQueryKey = "serviceReportFormSearchQueryKey";

export const serviceTabIndexKey = "serviceTabIndexKey";

export const eventLogSearchQueryKey = "eventLogSearchQueryKey";

export const serviceCommentMultiMediaViewKey = "serviceCommentMultiMediaViewKey";

export const taskMultiMediaViewKey = "taskMultiMediaViewKey";

export const eventLogMultiMediaViewKey = "eventLogMultiMediaViewKey";

export const vesselMultiMediaViewKey = "vesselMultiMediaViewKey";

export const eventLogPageKey = "eventLogPageKey";

export const fleetPagePageKey = "fleetPagePageKey";

export const servicePagePageKey = "servicePagePageKey";

export const adminTabIndexKey = "adminTabIndexKey";

export const templatesTabIndexKey = "templatesTabIndexKey";

export const softwareTabIndexKey = "softwareTabIndexKey";

export const softwareControlSystemTypeKey = "softwareControlSystemTypeKey";

export const serviceListSelectedStatusFilter = "serviceListSelectedStatusFilter"; //Remove

export const showDecommissionedVesselsKey = "showDecommissionedVesselsKey";

export const serviceListToggledArrayKey = "serviceListToggledArrayKey";

export const agentFleetSearchQueryKey = "agentFleetSearchQueryKey";

export const usersTabIndexKey = "usersTabIndexKey";

export const fleetTabIndexKey = "fleetTabIndexKey";

export const lowClassFleetSearchQueryKey = "lowClassFleetSearchQueryKey";

export const ultraJetTabIndexKey = "ultraJetTabIndexKey";

export const agentLowClassFleetSearchQueryKey = "agentLowClassFleetSearchQueryKey";

export const fleetPageSizeKey = "fleetPageSizeKey";

export const eventsPageSizeKey = "eventsPageSizeKey";

export const servicesPageSizeKey = "servicesPageSizeKey";

export const serviceAgentsPageSizeKey = "serviceAgentsPageSizeKey";

export const serviceAgentsPageKey = "serviceAgentsPageKey";

export const includeInactiveUsersKey = "includeInactiveUsersKey";
